import React from 'react';
import footerlogo from "../assets/applogo.png";
import footerlogoapp from "../assets/apptext.png";

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
     
            <footer>
                <div className="footerHolder">
                    <div className="birdlogo"></div>

                    <div className="copyRight">
                        <span> Copyright &copy;{new Date().getFullYear()}</span>{"."}

                        <span className="paddingADJF">
                            <img className="footerimgadj widthLog" src={footerlogo} />
                        </span>
                        <span>
                            <img className="footerimgadj" src={footerlogoapp} />

                        </span>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;