import React from "react";

import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import IdleTimer from 'react-idle-timer'

class PDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      sessionTimeOut: false,
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }
  onDocumentLoadSuccess(page) {
    this.setState({ numPages: page });
  }

  Back() {
    this.props.history.push("/");
  }

  handleOnAction(event) {
    console.log("user did something", event);

    
    if (event !== undefined) {

      this.setState({ sessionTimeOut: false });
    }
  }

  handleOnActive(event) {
    console.log("user is active", event);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle(event) {
    console.log("user is idle", event);
    console.log("last active", this.idleTimer.getLastActiveTime());

    if (event === undefined) {
      

      this.setState({ sessionTimeOut: true });
    }

  }

  componentDidUpdate(prevState) {
    // Typical usage (don't forget to compare props):
    if (this.state.sessionTimeOut !== prevState.sessionTimeOut) {

      if (this.state.sessionTimeOut === true) {
      this.props.history.push("/");
      }
    }
  }

  render() {
    console.log("Props Data -> ", this.props.history.location.state.detail);
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div
            style={{
              height: "80vh",
              marginLeft: "10%",
              width: "80%",
              marginTop: "5%",
              marginBottom: "5%",
             
            }}
          >
            <div
              style={{
                color: "#F07167",
                fontWeight: "bold",
                marginBottom: "1em",
                cursor: "pointer",
                paddingTop: "1em"
              }}
            >
              {" "}
              <span onClick={this.Back.bind(this)}>{"<"} Back </span>
            </div>
            <Viewer
              defaultScale={1}
              fileUrl={this.props.history.location.state.detail}
            />
          </div>
        </Worker>
      </div>
    );
  }
}

export default PDF;
