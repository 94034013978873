import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";



class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNo: "",
      dob: null,
      cardMessage: "",
      submitMessage: "",
      isLoaded: false,
    };
  }

  // Handle change Retrieval Code
  changeCardNo(e) {
    if (e.target.value.length <= 19) {
      this.setState({ cardMessage: "" });
      this.setState({ submitMessage: "" });
      this.setState({ cardNo: e.target.value.toUpperCase() });
    }
  }

  // Enter to submit
  getKeycode(e) {
    return e.keyCode;
  }

  //Handle Change date
  changeDod(date) {
    this.setState({ submitMessage: "" });

    this.setState({ dob: date });
  }

  //Split Retrieval Code by "-"
  keyDown(e) {
    if (e.keyCode != 8) {
      if (this.state.cardNo.length < 19) {
        if (e.key.match("[A-Za-z0-9]") != null) {
          if (
            this.state.cardNo.length == 4 ||
            this.state.cardNo.length == 9 ||
            this.state.cardNo.length == 14
          ) {
            let val = this.state.cardNo + "-";
            this.setState({ cardNo: val });
          }
        }
      }
    }
    if (e.keyCode == 8) {
    }
  }

  //Form Submission

  submit(stateObj, e) {
    if (this.state.cardNo.length < 19 || this.state.cardNo.length == 0) {
      this.setState({ cardMessage: "Please enter the retrieval code" });
    } else if (this.state.dob == null) {
      this.setState({ dob: "" });
    }

    if (this.state.dob != null && this.state.cardNo.length >= 19) {
      let dateString =
        this.state.dob.getFullYear() +
        "-" +
        ("0" + (this.state.dob.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + this.state.dob.getDate()).slice(-2);

      //   http://ec2-54-211-237-113.compute-1.amazonaws.com:8092
      //   https://staging-api.hummingbird.health
      // https://api.hummingbird.health/api

      let url =
        process.env.REACT_APP_API_ENDPOINT + "/api/test/info/get?retrievalCode=" +
        this.state.cardNo +
        "&dob=" +
        dateString;

      this.setState({
        isLoaded: true
      })

      axios.get(url).then((res) => {
        if (res.data.statusCode == 200) {

          this.setState({
            isLoaded: false
          })

          this.props.history.push({
            pathname: "/resultPDF",
            state: { detail: res.data.info.preSignedUrl },
          });
        } else {
          this.setState({ submitMessage: "No Test information found", isLoaded: false });
        }
      });
    }
  }


  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.submit()
    }
  }

  //
  render() {
    return (
      <div className="Result">

        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) :
          <div className="bodyContent">
            <div className="bodyClass">
              <div className="Heading h4">
                Welcome to the Hummingbird Health COVID-19 SecureLink results
                portal.
            </div>
              <div className="downloadMessage">
                Download your test result using the 16 digit retrieval code sent
                as a text message to your cellphone, along with your date of
                birth.
            </div>
              <div className="formCheck">
                <div className="FormData">
                  <form>
                    <label htmlFor="defaultFormLoginEmailEx">
                      <span className="Heading h4"> Retrieval Code*</span>
                    </label>

                    <div>
                      <TextField
                        id="standard-required"
                        placeholder="####-####-####-####"
                        value={this.state.cardNo}
                        onChange={this.changeCardNo.bind(this)}
                        onKeyDown={this.keyDown.bind(this)}
                        onKeyUp={this.SignInC.bind(this)}
                        autoComplete='off'
                        type='text'
                      />
                    </div>
                    {this.state.cardMessage.length > 0 ? (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        *{this.state.cardMessage}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <p className="mb-2" style={{ fontSize: "13px" }}>
                      16 digit retrieval code sent as a text message.
                  </p>

                    <br />
                    <label htmlFor="defaultFormLoginPasswordEx">
                      <span className="Heading h4"> Date of Birth*</span>
                    </label>

                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="MM/DD/YYYY"
                          format="MM/dd/yyyy"
                          value={this.state.dob}
                          onChange={(date) => this.changeDod(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          autoOk
                          onKeyUp={this.SignInC.bind(this)}
                          autoComplete='off'
                          type='text'
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <p className="mb-2" style={{ fontSize: "13px" }}>
                      The date of birth for the individual tested.
                  </p>


                    <div className="text-center mt-4">
                      <input
                        type="button"
                        value="Submit"
                        onClick={this.submit.bind(this, this.state)}
                      />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                      <Link to="/trouble-form">I need help viewing my results</Link>
                    </div>
                    {this.state.submitMessage.length > 0 ? (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        *{this.state.submitMessage}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>}
      </div>
    );
  }
}

export default Result;
