import React from 'react';
import footerlogo from "../assets/applogo1.png";
import footerlogoapp from "../assets/apptext.png";
import bvilogo from "../assets/beaconBVI.png";

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <div className="headerHolder">
                    <div className="lefImage">
                        <span>
                        {window.location.hostname.includes('usvi.care') ?
                            <img className="" src={footerlogo} />
                            :
                            <img className="" src={bvilogo} />
                        }
                        </span>
                        {/* <span>
                            <img className="footerimgadj" src={footerlogoapp} />
                        </span> */}
                    </div>

                    <div className="rightImage"></div>
                </div>
            </div>
        )
    }
}

export default Header;