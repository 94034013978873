import React from 'react';
import bannerImage from "../assets/bannerImage.png";

class Banner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="bannerHolder">
                <div className="bannerContent">
                    <h1> COVID - 19 Result Portal</h1>

                    <p>This form is used to get the result of covid - 19.</p>
                </div>
                <div className="bannerImage">
                    <img src={bannerImage} alt="banner" className="bannerRight" />
                </div>
            </div>
        )
    }
}

export default Banner;