import React, { useEffect, useRef } from "react";
import "./troubleForm.css";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";


export default function TroubleForm() {
  //Form Initialization
  const initialFormData = Object.freeze({
    //first accordion
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    comments: "",
  });

  const [formstate, setformState] = React.useState(initialFormData);

  // Whole form Textfield Handle Change event
  const handleChangeForm = (event) => {
    const name = event.target.name;
    setformState({
      ...formstate,
      [name]: event.target.value,
    });
  };

  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");

  const mobileNumberChange = (number) => {
    setNumber(number);
  };

  //date of birth date state and Handle change
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //date of birth date state and Handle change
  const [selectedDateTest, setSelectedDateTest] = React.useState(null);

  const handleDateChangeTest = (date) => {
     setSelectedDateTest(date);
    // if(stateCheckedDonot===true){
    //   setSelectedDateTest(null);
    // }else{
    //   setSelectedDateTest(date);
    // }
   
  };



  console.log("selectedDateTest",selectedDateTest)

  //Not Applicable state and Handle Change

  const [stateCheckedNA, setStateCheckNA] = React.useState(false);

  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  //Do not recall state and Handle Change

  const [stateCheckedDonot, setStateCheckDonot] = React.useState(false);

  const handleChangeDonot = (event) => {
    setStateCheckDonot(event.target.checked);

    if(stateCheckedDonot===false){
      setSelectedDateTest(null)
    }
  };


  console.log("stateCheckedDonot",stateCheckedDonot)

   //Receive message

  const [stateCheckedReceive, setStateCheckReceive] = React.useState(false);

  const handleChangeReceive = (event) => {
    setStateCheckReceive(event.target.checked);
  };


  const BackSubmit = (e) => {
    // history.push("/")
    window.location.replace("/")
  }

  const formSubmit = (e) => {



    if (formstate.firstName == "" && formstate.firstName.length == 0) {
      toast.error("Please enter first name");
     
      return;
    } else if (formstate.lastName == "" && formstate.lastName.length == 0) {
      toast.error("Please enter last name");
      return;
     
    }else if (newnumber == "" && newnumber.length == 0) {
      toast.error("Please enter mobile number");
      return;
    
    } 
    else if (newnumber.length !== 11) {
      toast.error("Please enter 10 digit mobile number");
      return;
      // myRef.current.scrollIntoView();
    } 

    else if (
      stateCheckedNA === false &&
      formstate.email == "" &&
      formstate.email.length == 0
    ) {
      toast.error("Please enter email id");
      return;
    
    } else if (
      stateCheckedNA === false &&
      !(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(formstate.email)) &&
      formstate.email != ""
    ) {
      toast.error("Please enter email id in correct format");
      return;
      // myRef.current.scrollIntoView();
    } 
    
    else if (selectedDate == null || selectedDate == "") {
      toast.error("Please select date of birth");
      return;
    }
    else if (selectedDate == "Invalid Date" || moment(selectedDate).format("yyyy") <= "1899" == true || selectedDate >new Date () == true) {
      toast.error("Please enter valid  DOB Date");
     
      return;
 
    } 

    else if (selectedDateTest == null && stateCheckedDonot===false) {
      toast.error("Please select valid test date");
      return;
    }
    else if (selectedDateTest == "Invalid Date" || moment(selectedDateTest).format("yyyy") <= "1899" == true || selectedDateTest >new Date () == true) {
      toast.error("Please enter valid  Date of test");
     
      return;
 
    } 


    // else if (formstate.comments == "" && formstate.comments == 0) {
    //   toast.error("Please enter comments");
     
    // }
    console.log("worling",selectedDate)


    if (
      formstate.firstName !== "" 
      // &&
      // formstate.lastName !== "" &&
      // newnumber !== "" &&  

      // formstate.email!=="" 
 


    ) {

    var finaldateDob =
      selectedDate.getFullYear() +
      "-" +
      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + selectedDate.getDate()).slice(-2);

    if (stateCheckedDonot===false) {
      var finaldateTest =
        selectedDateTest.getFullYear() +
        "-" +
        ("0" + (selectedDateTest.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + selectedDateTest.getDate()).slice(-2);
    }

    console.log("worling")

    let data = {
      ...formstate,

      firstName: formstate.firstName.trim(),
      lastName: formstate.lastName.trim(),
      mobileNumber: "+" + newnumber,
      email: stateCheckedNA === true ? "N/A" : formstate.email.trim(),
      dob: finaldateDob === undefined ? null : finaldateDob,
      dateOfTest: finaldateTest === undefined ? null:finaldateTest,
      doNotRecall: stateCheckedDonot === true ? true : false,
      canReceiveText:stateCheckedReceive===true?true:false
      // comments: formstate.comments.trim(),
    };

    console.log("Formdata", data);

    axios.post(process.env.REACT_APP_API_ENDPOINT+"/api/form/trouble/submit", data)
    .then((response) => {
      console.log("response",response);

      if(response.data.errorCode===400){
        console.log("errorMessage",response.data.errorMessage)
        toast.error(response.data.errorMessage)
      } else if (response.data.statusCode===200){
        console.log("info",response.data.info)
        toast.success("A support request has been raised will contact you shortly")


        setTimeout(()=> {
          window.location.reload();
       }, 1500)
        
        // setformState(initialFormData)
        // setSelectedDate(null)
        // setSelectedDateTest(null)
        // setNumber("1")

        // setStateCheckNA(false)
        // setStateCheckDonot(false)
        // setStateCheckReceive(false)
      }
    }, (error) => {
      console.log(error);
      toast.error(error)

      setTimeout(()=> {
        window.location.reload();
     }, 1500)

    });

  }


  };

  return (
    <>

<ToastContainer />

      <div className="troubleForm">
        <div className="troubleContainer">
          <div className="realTroubleHolder">
            <Grid container spacing={2}
           
              alignItems="center"
             
            >
              {/* <h1>Having Trouble in viewing results?</h1> */}
              <Grid item xs={12} md={6} sm={12} >
                <TextField
                  id="standard-required"
                  label="First Name *"
                  autoComplete="off"
                  type="text"
                  onChange={handleChangeForm}
                  name="firstName"
                  value={formstate.firstName}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-required"
                  label="Last Name *"
                  autoComplete="off"
                  type="text"
                  onChange={handleChangeForm}
                  name="lastName"
                  value={formstate.lastName}
                />
              </Grid>

              <Grid item xs={12} md={12} sm={12} style={{paddingTop:"0px"}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of Birth (MM/DD/YYYY) *"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    autoOk
                    // onKeyUp={this.SignInC.bind(this)}
                    maxDate={new Date()}
                    autoComplete="off"
                    type="text"
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={6} sm={12} style={{paddingTop:"0px",paddingBottom:"0px"}}>
                <InputLabel
                  id="demo-simple-select-label"
                  
                >
                  Mobile Number *
                </InputLabel>
                <PhoneInput
                  country={"us"}
//                   onlyCountries={["us"]}
                  countryCodeEditable={false}
//                   disableDropdown={true}
                  onChange={mobileNumberChange}
                  value={newnumber}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12} style={{paddingTop:"27px"}}>
              <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeReceive}
                      name="receivemessage"
                      value="receivemessage"
                    />
                  }
                  label=" This number can receive text messages"
                 
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12} style={{paddingTop:"0px",paddingBottom:"0px"}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of Test (MM/DD/YYYY) *"
                    format="MM/dd/yyyy"
                    value={selectedDateTest}
                    disabled={stateCheckedDonot === true ? true : false}
                    onChange={(date) => handleDateChangeTest(date)}
                    maxDate={new Date()}
                    autoOk
                    autoComplete="off"
                    type="text"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeDonot}
                      name="Donot"
                      value="Donot"
                    />
                  }
                  label=" Do not recall"
                 
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12} style={{paddingTop:"0px"}}>
                <TextField
                  id="standard-required"
                  label="Email *"
                  autoComplete="off"
                  type="text"
                  onChange={handleChangeForm}
                  name="email"
                  value={stateCheckedNA === true ? "N/A" : formstate.email}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeNA}
                      name="N/A"
                      value="N/A"
                    />
                  }
                  label="N/A - Not Applicable"
                 
                />
              </Grid>

              <Grid item xs={12} md={6} sm={12} style={{paddingTop:"0px"}}>
                <TextField
                  id="standard-required"
                  label="Comments"
                  autoComplete="off"
                  type="text"
                  onChange={handleChangeForm}
                  name="comments"
                  value={formstate.comments}
                />
              </Grid>

              <div className="submitTroubleHolder">

          
           

                <div>

                <input
                  type="button"
                  value="Back"
                  className="submitTrouble"
                  onClick={BackSubmit}
                />

                </div>

                <div>
                <input
                  type="button"
                  value="Submit"
                  className="submitTrouble"
                  onClick={formSubmit}
                />

                </div>


              </div>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
