import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Result from './Components/Result/Result'
import Footer from './Components/Footer/Footer'
import Header from './Components/Header/Header'
import PDF from './Components/PDF/PDF'
import Banner from './Components/Banner/Banner'

import TroubleForm from './Components/TroubleForm/TroubleForm'
function App() {
  return (
    <div className="App">
      <Header />
      {/* <Banner/> */}
      <Router>
        <Route path="/" exact component={Result} />
        <Route path="/resultPDF" component={PDF}/>
        <Route path="/trouble-form" component={TroubleForm}/>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
